<template>
  <div class="body">
    <top :isOpen="isOpen"></top>
    <!-- 广告图 -->
    <div class="advertisement_box">
      <div class="advertisement">
        <img :src="ColumnImgSrc" alt="" />
      </div>
    </div>
    <!-- 当前位置 -->
    <div class="place_box">
      <div class="place">
        <div class="place_image">
          <img src="../assets/Search_page/109.png" alt="" />
        </div>
        <!-- 位置 -->
        <div class="place_font">
          <p>当前位置：</p>
          <p @click="toIndex">首页</p>
          <p>></p>
          <p @click="toPage">{{ classify }}</p>
          <p>></p>
          <p>正文</p>
        </div>
      </div>
    </div>
    <!-- 大盒子 -->
    <div class="box_content">
      <div class="box">
        <!-- 左边的内容 -->
        <div class="box_left">
          <!-- 左边内容的第一块 -->
          <div class="box_left_one">
            <div class="box_left_one_a">
              <!-- 标题 -->
              <h1 v-html="ContentData.title"></h1>
              <!-- 头像+昵称+来源+日期+ 阅读量+收藏 -->
              <div class="box_left_one_content">
                <!-- 头像 -->
                <div class="head_image">
                  <img src="../assets/home/1.png" alt="" />
                </div>
                <!-- 内容 -->
                <div class="head_font">
                  <p>
                    {{ ContentData.useradmin }} | 来源：{{
                      ContentData.source
                    }}
                    | 发布日期：{{ ContentData.updated_at }} | 阅读量：{{
                      ContentData.read_num
                    }}
                    | 收藏：{{ shoucnagliang }} | 点赞数：{{ dianzanliang }}
                  </p>
                </div>
              </div>
              <!-- 标签 -->
              <div class="label_box">
                <span
                  v-for="(item, index) in ContentData.tagestitle"
                  :key="index"
                  >{{ item.name }}</span
                >
              </div>
              <!-- 游览次数+收藏+点赞 -->
              <div class="frequency_box">
                <!-- 剩余游览次数 -->
                <div class="frequency">
                  <!-- <div class="frequency_content" v-show="showhidden">
                    <p>
                      今天剩余<span>{{ enshrine }}</span
                      >次内容游览次数
                    </p>
                    <div class="frequency_button">
                      <button @click="vip">开通VIP</button>
                    </div>
                  </div> -->
                </div>
                <!-- 收藏+点赞按钮 -->
                <div class="frequency_box_right">
                  <div class="frequency_rigth_button">
                    <button @click="collect">
                      <img src="../assets/text/116.png" />{{ collectState }}
                    </button>
                    <button @click="giveLike">
                      <img src="../assets/text/127.png" />{{ likeState }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 左边内容的第二块 -->
          <div class="box_left_tow">
            <!-- 内容 -->
            <div
              style="width: 94%; margin: 0 auto"
              v-html="colorkeyword(ContentData.content)"
            ></div>
          </div>
          <!-- 左边内容的第三块 -->
          <div class="box_left_three">
            <!-- 收藏点赞 -->
            <div class="box_left_three_content">
              <div class="box_left_three_button">
                <button @click="collect">
                  <img src="../assets/text/119.png" />{{ collectState }}
                </button>
                <button @click="giveLike">
                  <img src="../assets/text/120.png" />{{ likeState }}
                </button>
              </div>
            </div>
          </div>
          <!-- 左边内容的第四块 -->
          <div class="box_left_four">
            <!-- 上一篇 -->
            <div
              class="box_left_four_left"
              @click="toPrevious(previous.id)"
              v-if="previousState"
            >
              <span>上一篇：</span>{{ previous.title }}
            </div>
            <div class="box_left_four_left2" v-else>
              <span>上一篇没有了</span>
            </div>
            <!-- 下一篇 -->
            <div
              class="box_left_four_right"
              v-if="nextState"
              @click="toNext(next.id)"
            >
              <span>下一篇：</span>{{ next.title }}
            </div>
            <div class="box_left_four_right2" v-else>
              <span>下一篇没有了</span>
            </div>
          </div>
        </div>
        <!-- 右边的内容 -->
        <div class="box_right">
          <!-- 热门阅读 -->
          <!-- 右边内容的第一部分 -->
          <div class="box_right_box">
            <div class="box_right_content">
              <!-- 标题 -->
              <div class="box_right_box_title">
                <!-- 前面的竖线 -->
                <div class="wire"></div>
                <h2>推荐阅读</h2>
              </div>
              <!-- 文字内容 -->
              <div class="box_right_box_font">
                <div
                  class="text_font"
                  v-for="item in ReReadData"
                  :key="item.id"
                  @click="toText(item.id)"
                >
                  <!-- <div class="text_font_one iconfont icon-icon-test2"></div> -->
                  <div :class="item.className"></div>
                  <div class="text_font_tow">
                    <p>{{ item.title }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 右边内容的第二部分 -->
          <div class="box_right_box box_right_tow">
            <div class="box_right_content">
              <!-- 标题 -->
              <div class="box_right_box_title">
                <!-- 前面的竖线 -->
                <div class="wire"></div>
                <h2>热门阅读</h2>
              </div>
              <!-- 文字内容 -->
              <div class="box_right_box_font">
                <div
                  class="text_font"
                  v-for="item in HotReadData"
                  :key="item.id"
                  @click="toText(item.id)"
                >
                  <div class="text_font_tow text_font_tow_font">
                    <p>{{ item.title }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 右边内容的第三部分 -->
          <div class="box_right_box box_right_three">
            <div class="box_right_content">
              <!-- 标题 -->
              <div class="box_right_box_title">
                <!-- 前面的竖线 -->
                <div class="wire"></div>
                <h2>行业资讯</h2>
              </div>
              <!-- 文字内容 -->
              <div class="box_right_box_font">
                <div
                  class="text_font text_font_right_font"
                  v-for="item in MessageData"
                  :key="item.id"
                  @click="toContent(item.id)"
                >
                  <div class="box_right_three_image">
                    <!-- <img src="../assets/text/4.png" alt="" /> -->
                    <img :src="'/api' + item.imgUrl.url" alt="" />
                  </div>
                  <div class="text_font_tow">
                    <p>{{ item.title }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 侧边栏 -->
    <sidebar></sidebar>
    <!-- 底部 -->
    <bottom></bottom>
  </div>
</template>
<script>
import {
  getTextReRead,
  getTextHotRead,
  getTextMessageData,
  textCollect,
  getColumnImg,
} from "../http";
import Qs from "qs";
export default {
  data() {
    return {
      isOpen: 0,
      collectState: "收藏",
      likeState: "点赞",
      scrollTop: 0,
      keywords: "",
      ContentData: {},
      ReReadData: [],
      HotReadData: [],
      MessageData: [],
      ColumnImgSrc: "",
      previous: {},
      previousState: true,
      next: {},
      nextState: true,
      classify: "",
      uid: "",
      login_key: "",
      id: "",
      userdata: [], //用户点赞收藏
      showhidden: true,
      class: "",
      enshrine: "",
      collection: "",
      quantity: "",
      shoucnagliang: null,
      dianzanliang: null,
    };
  },
  mounted() {
    if (this.$route.query.keywords) {
      this.keywords = this.$route.query.keywords;
    } else {
      this.keywords = "";
    }
    this.getContentData();
    //获取推荐阅读
    this.getReRead();
    //获取热门阅读
    this.getHotRead();
    //获取行业资讯
    this.getMessage();
    //获得栏目图
    this.getTheColumnChart();
    //判断是什么分类
    this.changeClassify();
  },
  created() {
    window.addEventListener("scroll", this.showSearch);
    //获取内容详情
    this.id = this.$route.query.id;
    //从sessionStorage中获取用户的类型
    var infostr = sessionStorage.getItem("key");
    var keyaaa = JSON.parse(infostr);
    this.uid = keyaaa?.uid;
    this.login_key = keyaaa?.openid;
    // this.class = keyaaa.class
    // console.log(this.class);
    if (keyaaa?.class >= 1) {
      this.showhidden = false;
    } else {
      this.showhidden = true;
    }
  },
  watch: {
    $route: {
      async handler() {
        let id = this.$route.query.id;
        console.log(id);
        // this.getContentData(id);
      },
      deep: true,
    },
  },
  methods: {
    //收藏
    async collect() {
      let that = this;
      let sid = this.$route.query.id;
      if (this.collectState == "收藏") {
        this.$axios({
          method: "post",
          url: "/api/shopmini/wap_Collect.html",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          data: Qs.stringify({
            sid: Number(sid),
            uid: this.uid,
            login_key: this.login_key,
          }),
        })
          .then(function (res) {
            console.log(res);
            if (res.data.msg == "收藏成功" || res.data.msg == "已收藏") {
              that.collectState = "已收藏";
              that.shoucnagliang = Number(that.shoucnagliang) + 1;
              return;
            } else {
              if (res.data.static == 0 && res.data.msg == '请先登录') {
                that.$message.warning('请先登录')
                that.isOpen = 1
                setTimeout(()=>{
                  that.isOpen = 0
                }, 200)
              }
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      } else {
        this.$axios({
          method: "post",
          url: "/api/shopmini/wap_DelCollect.html",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          data: Qs.stringify({
            sid: Number(sid),
            uid: this.uid,
            login_key: this.login_key,
          }),
        })
          .then(function (res) {
            console.log(res.data.msg);
            if (res.data.msg == "取消成功") {
              that.collectState = "收藏";
              that.shoucnagliang = Number(that.shoucnagliang) - 1;
              return;
            } else {
              if (res.data.static == 0 && res.data.msg == '请先登录') {
                that.$message.warning('请先登录')
                that.isOpen = 1
                setTimeout(()=>{
                  that.isOpen = 0
                }, 200)
              }
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },
    // 过滤匹配当前id和集合id是否相等
    flier(id, collects) {
      // debugger;
      var reg = RegExp("/" + id + "/");
      collects.forEach((item, index) => {
        return reg.test(item == id);
      });
    },
    //点赞
    giveLike() {
      let that = this;
      let sid = this.$route.query.id;
      if (this.likeState == "点赞") {
        this.$axios({
          method: "post",
          url: "/api/shopmini/wap_GoLike.html",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          data: Qs.stringify({
            // sid: Number(sid),
            sid: that.id,
            uid: this.uid,
            login_key: this.login_key,
          }),
        })
          .then(function (res) {
            if (
              res.data.msg == "以点赞成功" ||
              res.data.msg == "此文章已经点赞" ||
              res.data.msg == "点赞成功"
            ) {
              that.likeState = "已点赞";
              that.dianzanliang = Number(that.dianzanliang) + 1;
              return;
            } else {
              if (res.data.static == 0 && res.data.msg == '请先登录') {
                that.$message.warning('请先登录')
                that.isOpen = 1
                setTimeout(()=>{
                  that.isOpen = 0
                }, 200)
              }
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      } else {
        this.$axios({
          method: "post",
          url: "/api/shopmini/wap_DelGoLike.html",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          data: Qs.stringify({
            sid: Number(sid),
            uid: this.uid,
            login_key: this.login_key,
          }),
        })
          .then(function (res) {
            if (res.data.msg == "以取消失败" || res.data.msg == "取消成功") {
              that.likeState = "点赞";
              that.dianzanliang = Number(that.dianzanliang) - 1;
              return;
            } else {
              if (res.data.static == 0 && res.data.msg == '请先登录') {
                that.$message.warning('请先登录')
                that.isOpen = 1
                setTimeout(()=>{
                  that.isOpen = 0
                }, 200)
              }
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },
    changeClassify() {
      let page = this.$route.query.page;
      // console.log(page);
      if (page == 0) {
        this.classify = "段子";
      } else if (page == 1) {
        this.classify = "短剧本";
      } else if (page == 2) {
        this.classify = "短文";
      } else {
        return;
      }
    },
    //点击跳转页面
    toPage() {
      if (this.classify == "段子") {
        this.$router.push({
          path: "/Segment",
          query: {
            page: 0,
          },
        });
      } else if (this.classify == "短剧本") {
        this.$router.push({
          path: "/Short_play",
          query: {
            page: 1,
          },
        });
      } else if (this.classify == "短文") {
        this.$router.push({
          path: "/Essay",
          query: {
            page: 2,
          },
        });
      } else {
        return;
      }
    },
    //吸顶监听
    showSearch() {
      // 获取当前滚动条向下滚动的距离
      let scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      // 当页面滚动到高度300px处时，显示搜索框
      // console.log(scrollTop);
      this.scrollTop = scrollTop;
    },
    // 获取内容详情
    async getContentData() {
      let that = this;
      this.$axios({
        method: "post",
        url: "/api/shopmini/wap_PieceContent.html",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: Qs.stringify({
          uid: this.uid,
          login_key: this.login_key,
          id: this.id,
        }),
      })
        .then(function (res) {
          if (res.data.static == 0) {
            console.log("进来了");
            // 判断普通会员用户观看次数
            that.$message({
              message: "今日浏览次数已用完",
              type: "warning",
            });
            that.$router.push({
              name: "Index",
            });
            return;
          }
          // 赋值全部数据
          that.ContentData = res.data;
          // 收藏量
          that.shoucnagliang = that.ContentData.data[0].collect_num;
          // 点赞量
          that.dianzanliang = that.ContentData.data[0].like_num;
          // 获取今天剩余观看次数
          that.enshrine = that.ContentData.userdata.snum;
          // 收藏状态
          that.collection = that.ContentData.data.Collectss;
          // 点赞状态
          that.quantity = that.ContentData.data.getlike;
          console.log(that.ContentData.data.getlike);
          if (that.ContentData.stutic != 0) {
            if (that.ContentData.previous) {
              // debugger;
              that.previous = that.ContentData.previous;
              that.previousState = true;
            } else {
              that.previousState = false;
            }
            if (that.ContentData.next) {
              that.next = that.ContentData.next;
              that.nextState = true;
            } else {
              that.nextState = false;
            }
            // debugger;
            that.ContentData = that.ContentData.data[0];

            // 页面初始化判断收藏的状态值
            if (that.collection == 0) {
              that.collectState = "收藏";
            } else if (that.collection == 1) {
              that.collectState = "已收藏";
            } else {
              console.log(1);
            }
            // 页面初始化判断点赞的状态值
            console.log(that.quantity);
            if (that.quantity == 0) {
              that.likeState = "点赞";
            } else if (that.quantity == 1) {
              that.likeState = "已点赞";
            } else {
              console.log(2);
            }
          } else {
            console.log(that.ContentData.msg);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    //获取推荐阅读
    async getReRead() {
      let ReReadData = await getTextReRead();
      // debugger
      // console.log(ReReadData);
      if (ReReadData.length == 1) {
        ReReadData[0].className = "text_font_one iconfont icon-icon-test2";
        this.ReReadData = ReReadData;
      } else if (ReReadData.length == 2) {
        ReReadData[0].className = "text_font_one iconfont icon-icon-test2";
        ReReadData[1].className = "text_font_one iconfont icon-icon-test";
        this.ReReadData = ReReadData;
      } else if (ReReadData.length == 3) {
        ReReadData[0].className = "text_font_one iconfont icon-icon-test2";
        ReReadData[1].className = "text_font_one iconfont icon-icon-test";
        ReReadData[2].className = "text_font_one iconfont icon-icon-test1";
        this.ReReadData = ReReadData;
      } else if (ReReadData.length == 4) {
        ReReadData[0].className = "text_font_one iconfont icon-icon-test2";
        ReReadData[1].className = "text_font_one iconfont icon-icon-test";
        ReReadData[2].className = "text_font_one iconfont icon-icon-test1";
        ReReadData[3].className = "text_font_one iconfont icon-icon-test3";
        this.ReReadData = ReReadData;
      } else if (ReReadData.length == 5) {
        ReReadData[0].className = "text_font_one iconfont icon-icon-test2";
        ReReadData[1].className = "text_font_one iconfont icon-icon-test";
        ReReadData[2].className = "text_font_one iconfont icon-icon-test1";
        ReReadData[3].className = "text_font_one iconfont icon-icon-test3";
        ReReadData[4].className = "text_font_one iconfont icon-icon-test4";
        this.ReReadData = ReReadData;
      } else {
        let newArr = [];
        ReReadData.forEach((item, index) => {
          if (index <= 4) {
            newArr.push(item);
          }
        });
        newArr[0].className = "text_font_one iconfont icon-icon-test2";
        newArr[1].className = "text_font_one iconfont icon-icon-test";
        newArr[2].className = "text_font_one iconfont icon-icon-test1";
        newArr[3].className = "text_font_one iconfont icon-icon-test3";
        newArr[4].className = "text_font_one iconfont icon-icon-test4";
        this.ReReadData = newArr;
      }
      // this.winReload()
      // console.log(ReReadData[1]);
      // this.ReReadData = ReReadData;
    },
    //获取热门阅读
    async getHotRead() {
      let HotReadData = await getTextHotRead();
      let newArr = [];
      HotReadData.forEach((item, index) => {
        if (index <= 4) {
          newArr.push(item);
        }
      });
      this.HotReadData = newArr;
      // this.winReload()
      // console.log(HotReadData);
    },
    //获取行业资讯
    async getMessage() {
      let messageData = await getTextMessageData();
      // console.log(messageData);
      this.MessageData = messageData;
      // this.winReload()
    },
    //跳转到详情页
    toText(id) {
      console.log(id);
      let page = this.$route.query.page;
      this.$router.push({
        path: "/Text",
        query: {
          id,
          page,
        },s
      });
      // this.$forceUpdate()
      this.winReload();
    },
    // 页面刷新
    winReload(cond) {
      window.location.reload();
    },
    //去首页
    toIndex() {
      this.$router.push({
        path: "/Index",
      });
    },
    //跳转到详情页
    toContent(id) {
      console.log(id);
      this.$router.push({
        path: "/Content",
        query: {
          page: 5,
          id,
          count: 2,
          type: 1,
        },
      });
    },
    //获得栏目图
    async getTheColumnChart() {
      let imgSrc = await getColumnImg();
      this.ColumnImgSrc = imgSrc;
      // console.log(this.ColumnImgSrc);
    },
    //去上一篇
    toPrevious(id) {
      this.$router.push({
        name: "Text",
        query: {
          page: this.$route.query.page,
          id,
        },
      });
      this.getContentData(this.$route.query.id);
      this.winReload();
    },
    //去下一篇
    toNext(id) {
      this.$router.push({
        name: "Text",
        query: {
          page: this.$route.query.page,
          id,
        },
      });
      this.getContentData(this.$route.query.id);
      this.winReload();
    },
    // vip
    vip() {
      this.$router.push("/vip");
    },
  },
  destroyed() {
    window.removeEventListener("scroll", this.showSearch);
  },
};
</script>

<style scoped>
/* input吸顶效果 */

.fixedSearch {
  position: fixed;
  /* width: 100%; */
  z-index: 2;
  height: 60px !important;
  background: rgb(255, 255, 255);
  top: 60px;
  /* overflow: hidden; */
}

.fixedSearchNext {
  height: 60px !important;
}

.disNone {
  display: none;
}

/* 吸顶效果结束 */
/* 页面 */

.body {
  width: 100%;
  min-width: 1200px;
  background-color: #fafafa;
}

/* 广告 */

.advertisement_box {
  width: 100%;
  min-width: 1200px;
  margin: auto;
  padding-top: 70px;
  background-color: #ffffff;
}

.advertisement {
  width: 1200px;
  margin: auto;
}

.advertisement img {
  width: 1200px;
}

/* 搜索 */

.seek {
  width: 100%;
  height: 130px;
  min-width: 1200px;
  background-color: #ffffff;
}

.seek-input {
  width: 1200px;
  height: 130px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.seek-box {
  width: 750px;
  height: 60px;
  display: flex;
  align-items: center;
}

.seek-one {
  display: flex;
  align-items: center;
  border: 1px #f62959 solid;
  border-radius: 8px;
}

.seek-one-icon {
  width: 24px;
  height: 24px;
  display: inline-block;
  vertical-align: middle;
  margin-left: 12px;
}

.seek-one-icon img {
  width: 100%;
}

.seek-one-input {
  width: 590px;
  display: inline-block;
}

.seek-one-input input {
  width: 99%;
  line-height: 38px;
  font-size: 16px;
  color: #999999;
  vertical-align: middle;
  background: none;
}

.seek-one-input input:checked {
  color: #999999;
}

.seek-one-button {
  width: 114px;
  height: 40px;
}

.seek-one-button button {
  width: 114px;
  height: 40px;
  background-color: #f62959;
  color: #ffffff;
  border-radius: 0px 8px 8px 0px;
  cursor: pointer;
  font-size: 18px;
}

.seek_input_font {
  width: 540px;
  position: relative;
  top: -10px;
}

.seek_input_font ul {
  display: flex;
  justify-content: space-between;
}

.seek_input_font ul li {
  cursor: pointer;
  font-size: 14px;
  color: #999999;
}

.seek_input_font ul li:hover {
  color: #f62959;
}

/* 当前位置 */

.place_box {
  width: 100%;
  min-width: 1200px;
  height: 60px;
  line-height: 60px;
  border-top: 1px #ededed solid;
}

.place {
  width: 1200px;
  height: 60px;
  line-height: 60px;
  margin: auto;
  display: flex;
}

/* 图标 */

.place_image img {
  vertical-align: middle;
}

/* 位置文字 */

.place_font {
  display: flex;
}

.place_font p {
  margin-left: 10px;
  color: #999999;
  font-size: 14px;
}

.place_font p:nth-of-type(n + 2) {
  cursor: pointer;
}

/* 大盒子 */

.box_content {
  width: 100%;
}

.box {
  width: 1200px;
  margin: auto;
  display: flex;
}

/* 盒子左边的内容 */

.box_left {
  width: 870px;
}

/* 盒子左边内容的第一块 */

.box_left_one {
  width: 100%;
  background-color: #ffffff;
  border-radius: 10px;
  padding-bottom: 10px;
}

.box_left_one_a {
  width: 94%;
  margin: auto;
}

/* 标题 */

.box_left_one_a h1 {
  font-size: 34px;
  line-height: 60px;
}

/* 内容 + 头像+昵称+来源+日期+ 阅读量+收藏 */

.box_left_one_content {
  width: 100%;
  height: 30px;
  color: #666666;
  display: flex;
  align-items: center;
}

/* 头像 */

.head_image {
  width: 24px;
  height: 24px;
}

.head_image img {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  vertical-align: middle;
}

/* 内容 */

.head_font {
  font-size: 14px;
  margin-left: 10px;
}

/* 标签 */

.label_box {
  width: 100%;
  margin-left: -10px;
}

.label_box span {
  color: #ff5c0d;
  border-radius: 2px;
  display: inline-block;
  padding: 4px 8px 4px 8px;
  background-color: #fff7f2;
  margin-left: 10px;
  margin-top: 10px;
}

/* 游览次数+收藏+点赞 */

.frequency_box {
  width: 100%;
  display: flex;
  align-items: center;
}

/* 剩余次数 */

.frequency {
  width: 50%;
  height: 60px;
  display: flex;
  align-items: center;
}

.frequency_content {
  width: 410px;
  height: 44px;
  background-image: url(../assets/text/91.png);
  background-repeat: no-repeat;
  background-size: 100%;
  display: flex;
  align-items: center;
}

.frequency_content p {
  width: 60%;
  font-size: 18px;
  font-weight: bold;
  text-indent: 15px;
}

.frequency_content span {
  color: #f62959;
}

/* 里面的按钮 */

.frequency_button {
  width: 30%;
  text-align: right;
  margin-right: 35px;
}

.frequency_button button {
  color: #fec677;
  font-weight: bold;
  padding: 5px 15px 5px 15px;
  background-color: #323232;
  border-radius: 15px;
}

/* 收藏+点赞 */

.frequency_box_right {
  width: 50%;
}

.frequency_rigth_button {
  width: 50%;
  margin: auto;
  display: flex;
  justify-content: center;
  margin-right: 20px;
}

/* 按钮 */

.frequency_rigth_button button {
  width: 84px;
  height: 36px;
  border-radius: 5px;
  font-size: 16px;
  margin-left: 15px;
}

.frequency_rigth_button button:nth-of-type(1) {
  color: #ffffff;
  background-color: #f62959;
  box-shadow: 0px 3px 6px 0px rgba(246, 41, 89, 0.2);
}

.frequency_rigth_button button:nth-of-type(1) img {
  width: 20px;
  height: 20px;
  vertical-align: middle;
  margin-right: 4px;
}

.frequency_rigth_button button:nth-of-type(2) {
  color: #f62959;
  background: none;
  border: 1px #f62959 solid;
  box-shadow: 0px 3px 6px 0px rgba(246, 41, 89, 0.2);
}

.frequency_rigth_button button:nth-of-type(2) img {
  width: 20px;
  height: 20px;
  vertical-align: middle;
  margin-right: 4px;
}

/* 盒子左边内容的第二块 */

.box_left_tow {
  padding: 10px 0;
  width: 100%;
  min-height: 490px;
  margin-top: 30px;
  background-color: #ffffff;
}

/* 内容 */

.box_left_tow_content {
  width: 94%;
  margin: auto;
  overflow: hidden;
}

/* 内容文字 */

.box_left_tow_one {
  width: 100%;
  margin-top: 10px;
}

.box_left_tow_one h3 {
  line-height: 40px;
  color: #333333;
}

.box_left_tow_one h5 {
  line-height: 30px;
  font-size: 14px;
  color: #333333;
}

.box_left_tow_one p {
  font-size: 14px;
  text-indent: 2em;
  color: #333333;
}

/* 左边盒子的第三块 */

.box_left_three {
  width: 100%;
  min-height: 108px;
}

/* 点赞收藏 */

.box_left_three_content {
  width: 50%;
  height: 108px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.box_left_three_button button {
  width: 200px;
  height: 60px;
  border-radius: 10px;
  margin-left: 10px;
  font-size: 24px;
}

.box_left_three_button button:nth-of-type(1) {
  color: #ffffff;
  background-color: #f62959;
  box-shadow: 0px 3px 6px 0px rgba(246, 41, 89, 0.2);
}

.box_left_three_button button:nth-of-type(1) img {
  width: 30px;
  height: 30px;
  vertical-align: middle;
  margin-right: 8px;
}

.box_left_three_button button:nth-of-type(2) {
  color: #f62959;
  background: none;
  border: 1px #f62959 solid;
  box-shadow: 0px 3px 6px 0px rgba(246, 41, 89, 0.2);
}

.box_left_three_button button:nth-of-type(2) img {
  width: 30px;
  height: 30px;
  vertical-align: middle;
  margin-right: 8px;
}

/* 左边盒子的第四块 */

.box_left_four {
  width: 100%;
  display: flex;
  align-items: center;
  color: #333333;
  margin: 20px 0;
}

.box_left_four_left {
  width: 50%;
  text-align: center;
  cursor: pointer;
}
.box_left_four_left > span {
  font-size: 18px;
}

.box_left_four_left:hover,
.box_left_four_right:hover {
  color: #f62959;
}

.box_left_four_left2,
.box_left_four_right2 {
  width: 50%;
  text-align: center;
  font-size: 18px;
}

.box_left_four_right {
  width: 50%;
  text-align: center;
  cursor: pointer;
}
.box_left_four_right > span {
  font-size: 18px;
}
/* 盒子右边内容 */

.box_right {
  width: 300px;
  margin-left: 30px;
}

/* 右边盒子的第一个内容 */

.box_right_box {
  width: 100%;
}

.box_right_content {
  width: 94%;
  margin: auto;
}

/* 标题 */

.box_right_box_title {
  height: 60px;
  display: flex;
  align-items: center;
  border-bottom: 1px #cccccc solid;
}

/* 线 */

.wire {
  width: 5px;
  height: 24px;
  background-color: #f62959;
}

.box_right_box_title h2 {
  font-size: 20px;
  margin-left: 10px;
  color: #333333;
}

/* 文字内容 */

.text_font {
  width: 100%;
  /* height: 40px; */
  margin-top: 20px;
  display: flex;
  align-items: center;
  color: #999999;
}

.text_font p:hover,
.text_font:hover {
  color: #f62959 !important;
  cursor: pointer;
}

.text_font_one {
  width: 40px;
  height: 40px;
  font-size: 40px;
  line-height: 40px;
  text-align: center;
}

.text_font_tow {
  margin-left: 16px;
}

.text_font_tow p {
  font-size: 15px;
  color: #333333;
  /* -webkit-line-clamp: 2; */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: elipsis;
}

/* 右边盒子的第二个内容 */

.box_right_tow {
  width: 100%;
  margin-top: 30px;
}

.text_font_tow_font {
  margin: 0;
}

/* 右边盒子的第三个内容 */

.box_right_three {
  width: 100%;
  margin-top: 30px;
}

.box_right_three_image img {
  vertical-align: middle;
  width: 82px;
  height: 57px;
}
</style>